import * as React from "react"
// import phone from "../images/telephone.png"
import email from "../images/email.png"
import pin from "../images/pin.png"
import time from "../images/time.png"
import facebook from "../images/facebook-icon.png"
import instagram from "../images/instagram-icon.png"

const Contact = () => {
  return (
    <div className="contact-section" id="contact">
      <h1>Contact</h1>
      <div className="book-section">
        <button className="bookButton" tabIndex={0}>
          <a
            target={`_blank`}
            className="bookButtonLink"
            href="https://europeanbeautyatelier20.booksy.com"
          >
            book an appointment
          </a>
        </button>
      </div>
      <div className="contact-data">
        {/* <div className="phone-number">
          <img className="icon-3" src={phone} alt="phone icon" />
          <a href="tel:777-888-999">777 888 999</a>
        </div> */}
        <div className="email-address">
          <img className="icon-4" src={email} alt="email icon" />
          <a href="mailto:europeanbeautyatelier@gmail.com">
            europeanbeautyatelier@gmail.com
          </a>
        </div>
      </div>
      <div className="address">
        <p className="addressText2">
          <img className="icon-1-2" src={pin} alt="address pin" />
          175 25th St, Ogden, UT
        </p>
        <p className="addressText2" style={{ paddingTop: `20px` }}>
          Monday - Friday
        </p>
        <p
          className="addressText2"
          style={{ paddingRight: `25px`, paddingTop: `0px` }}
        >
          <img className="icon-2-2" src={time} alt="open hours" />
          4pm - 6pm
        </p>
        <p className="addressText2" style={{ paddingBottom: `50px` }}>
          Saturday 8am - 2pm
          <span className="socialIcons">
            <a
              href="https://www.facebook.com/europeanbeautyatelier/"
              target={`_blank`}
            >
              <img className="icon-5" src={facebook} alt="facebook icon" />
            </a>
            <a
              href="https://www.instagram.com/europeanbeautyatelier/"
              target={`_blank`}
            >
              <img className="icon-6" src={instagram} alt="instagram icon" />
            </a>
          </span>
        </p>
      </div>

      <div className="iframe-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001.0588494958592!2d-111.97698129999999!3d41.220487999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87530ee097b38ec5%3A0x31bec12b0dcda657!2s175%2025th%20St%2C%20Ogden%2C%20UT%2084401%2C%20Stany%20Zjednoczone!5e0!3m2!1sen!2sen!4v1668896618616!5m2!1sen!2sen"
          width="800"
          height="450"
          style={{ border: `0`, paddingBottom: `20px` }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="google map"
        ></iframe>
      </div>
    </div>
  )
}

export default Contact
